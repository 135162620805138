import React from 'react';

export const VALIDATION_ERROR = 'app/App/VALIDATION_ERROR';
export const DEFAULT_ERROR_MESSAGE = 'Something went wrong. Please try again later.';
export const SERVER_ERROR = 'app/App/SERVER_ERROR';
export const UNKNOWN_ERROR = 'app/App/UNKNOWN_ERROR';
export const RESET_UNKNOWN_ERROR = 'app/App/RESET_UNKNOWN_ERROR';
export const PAGE_NOT_FOUND_ERROR = 'app/App/PAGE_NOT_FOUND_ERROR';
export const AUTHORIZATION_ERROR = 'AUTHORIZATION_ERROR'
export const RESET_AUTHORIZATION_ERROR = 'RESET_AUTHORIZATION_ERROR'

export const LANGUAGE_EN = 'en';
export const LANGUAGE_RU = 'ru';
export const LANGUAGE_ARM = 'arm';

export const CHANGE_APP_LANGUAGE = 'CHANGE_APP_LANGUAGE';

export const DROPDOWN_CANCEL_HIDE = 'DROPDOWN_CANCEL_HIDE';
export const DROPDOWN_HIDE = 'DROPDOWN_HIDE';

export  const AUTH_START = 'AUTH_START';
export  const AUTH_SUCCESS = 'AUTH_SUCCESS';
export  const AUTH_FAIL = 'AUTH_FAIL';

export  const AUTH_LOGOUT = 'AUTH_LOGOUT';
export  const AUTH_CHECK_STATE = 'AUTH_CHECK_STATE';
export  const AUTH_STATE_CHECKED_SCCESS = 'AUTH_STATE_CHECKED_SCCESS';



export const CONTENT_REQUEST_START = 'CONTENT_REQUEST_START';
export const CONTENT_REQUEST_SUCCESS_EN = 'CONTENT_REQUEST_SUCCESS_EN';
export const CONTENT_REQUEST_SUCCESS_RU = 'CONTENT_REQUEST_SUCCESS_RU';
export const CONTENT_REQUEST_SUCCESS_ARM = 'CONTENT_REQUEST_SUCCESS_ARM';

export const CONTENT_REQUEST_FAIL_EN = 'CONTENT_REQUEST_FAIL_EN';
export const CONTENT_REQUEST_FAIL_RU = 'CONTENT_REQUEST_FAIL_RU';
export const CONTENT_REQUEST_FAIL_ARM = 'CONTENT_REQUEST_FAIL_ARM';

export const SLIDE_1_LINE_1_PART_1 = 'SLIDE_1_LINE_1_PART_1';
export const SLIDE_1_LINE_1_PART_2 = 'SLIDE_1_LINE_1_PART_2';
export const SLIDE_1_LINE_2_PART_1 = 'SLIDE_1_LINE_2_PART_1';
export const SLIDE_1_LINE_2_PART_2 = 'SLIDE_1_LINE_2_PART_2';
export const SLIDE_1_PARAGRAPH = 'SLIDE_1_PARAGRAPH';

export const SLIDE_2_LINE_1_PART_1 = 'SLIDE_2_LINE_1_PART_1';
export const SLIDE_2_LINE_1_PART_2 = 'SLIDE_2_LINE_1_PART_2';
export const SLIDE_2_LINE_2_PART_1 = 'SLIDE_2_LINE_2_PART_1';
export const SLIDE_2_LINE_2_PART_2 = 'SLIDE_2_LINE_2_PART_2';
export const SLIDE_2_PARAGRAPH = 'SLIDE_2_PARAGRAPH';

export const SLIDE_3_LINE_1_PART_1 = 'SLIDE_3_LINE_1_PART_1';
export const SLIDE_3_LINE_1_PART_2 = 'SLIDE_3_LINE_1_PART_2';
export const SLIDE_3_LINE_2_PART_1 = 'SLIDE_3_LINE_2_PART_1';
export const SLIDE_3_LINE_2_PART_2 = 'SLIDE_3_LINE_2_PART_2';
export const SLIDE_3_PARAGRAPH = 'SLIDE_3_PARAGRAPH';

export const SLIDE_4_LINE_1_PART_1 = 'SLIDE_4_LINE_1_PART_1';
export const SLIDE_4_LINE_1_PART_2 = 'SLIDE_4_LINE_1_PART_2';
export const SLIDE_4_LINE_2_PART_1 = 'SLIDE_4_LINE_2_PART_1';
export const SLIDE_4_LINE_2_PART_2 = 'SLIDE_4_LINE_2_PART_2';
export const SLIDE_4_PARAGRAPH = 'SLIDE_4_PARAGRAPH';

export const NEWS_PAGE_PARAGRAPH = 'NEWS_PAGE_PARAGRAPH';

export const HOME_PAGE_ABOUT_US_CONTENT_P_1 = 'HOME_PAGE_ABOUT_US_CONTENT_P_1';
export const HOME_PAGE_ABOUT_US_CONTENT_P_2 = 'HOME_PAGE_ABOUT_US_CONTENT_P_2';
export const HOME_PAGE_ABOUT_US_CONTENT_P_3 = 'HOME_PAGE_ABOUT_US_CONTENT_P_3';
export const HOME_PAGE_ABOUT_US_CONTENT_P_4 = 'HOME_PAGE_ABOUT_US_CONTENT_P_4';







