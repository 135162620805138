
export  const CREATE_PRODUCT_START = 'CREATE_PRODUCT_START';
export  const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export  const CREATE_PRODUCT_FAIL = 'CREATE_PRODUCT_FAIL';


export  const REMOVE_PRODUCT_START = 'REMOVE_PRODUCT_START';
export  const REMOVE_PRODUCT_SUCCESS = 'REMOVE_PRODUCT_SUCCESS';
export  const REMOVE_PRODUCT_FAIL = 'REMOVE_PRODUCT_FAIL';

export const RESET_PRODUCT_MESSAGES = 'RESET_PRODUCT_MESSAGES'


export const GET_CARS_START = 'GET_CARS_START';
export const GET_CARS_SUCCESS = 'GET_CARS_SUCCESS';
export const GET_CARS_FAIL = 'GET_CARS_FAIL';

export  const CREATE_CAR_START = 'CREATE_CAR_START';
export  const CREATE_CAR_SUCCESS = 'CREATE_CAR_SUCCESS';
export  const CREATE_CAR_FAIL = 'CREATE_CAR_FAIL';


export  const REMOVE_CAR_START = 'REMOVE_CAR_START';
export  const REMOVE_CAR_SUCCESS = 'REMOVE_CAR_SUCCESS';
export  const REMOVE_CAR_FAIL = 'REMOVE_CAR_FAIL';

export  const ADD_CARS_TO_PRODUCT_START = 'ADD_CARS_TO_PRODUCT_START';
export  const ADD_CARS_TO_PRODUCT_SUCCESS = 'ADD_CARS_TO_PRODUCT_SUCCESS';
export  const ADD_CARS_TO_PRODUCT_FAIL = 'ADD_CARS_TO_PRODUCT_FAIL';


export  const CREATE_NEWS_START = 'CREATE_NEWS_START';
export  const CREATE_NEWS_SUCCESS = 'CREATE_NEWS_SUCCESS';
export  const CREATE_NEWS_FAIL = 'CREATE_NEWS_FAIL';

export  const REMOVE_NEWS_START = 'REMOVE_NEWS_START';
export  const REMOVE_NEWS_SUCCESS = 'REMOVE_NEWS_SUCCESS';
export  const REMOVE_NEWS_FAIL = 'REMOVE_NEWS_FAIL';

export  const CREATE_CONTENT_START = 'CREATE_CONTENT_START';
export  const CREATE_CONTENT_SUCCESS = 'CREATE_CONTENT_SUCCESS';
export  const CREATE_CONTENT_FAIL = 'CREATE_CONTENT_FAIL';

export  const REMOVE_CONTENT_START = 'REMOVE_CONTENT_START';
export  const REMOVE_CONTENT_SUCCESS = 'REMOVE_CONTENT_SUCCESS';
export  const REMOVE_CONTENT_FAIL = 'REMOVE_CONTENT_FAIL';
export const RESET_CONTENT_MESSAGES = 'RESET_CONTENT_MESSAGES';



export const RESET_NEWS_MESSAGES = 'RESET_NEWS_MESSAGES';

export const RESET_CAR_MESSAGES = 'RESET_CAR_MESSAGES';

export const PRODUCTS_SHORT_INFOS_REQUEST_START = 'PRODUCTS_SHORT_INFOS_REQUEST_START';
export const PRODUCTS_SHORT_INFOS_REQUEST_SUCCESS = 'PRODUCTS_SHORT_INFOS_REQUEST_SUCCESS';
export const PRODUCTS_SHORT_INFOS_REQUEST_FAIL = 'PRODUCTS_SHORT_INFOS_REQUEST_FAIL';

export const PRODUCT_ID_REQUEST_START = 'PRODUCT_ID_REQUEST_START';
export const PRODUCT_ID_REQUEST_SUCCESS = 'PRODUCT_ID_REQUEST_SUCCESS';
export const PRODUCT_ID_REQUEST_FAIL = 'PRODUCT_ID_REQUEST_FAIL';








