export const CHANGE_PRODUCT_TYPE = 'CHANGE_PRODUCT_TYPE';
export const PRODUCTS_REQUEST_START = 'PRODUCTS_REQUEST_START';
export const PRODUCTS_REQUEST_SUCCESS_EN = 'PRODUCTS_REQUEST_SUCCESS_EN';
export const PRODUCTS_REQUEST_SUCCESS_RU = 'PRODUCTS_REQUEST_SUCCESS_RU';
export const PRODUCTS_REQUEST_SUCCESS_ARM = 'PRODUCTS_REQUEST_SUCCESS_ARM';

export const PRODUCTS_REQUEST_FAIL_EN = 'PRODUCTS_REQUEST_FAIL_EN';
export const PRODUCTS_REQUEST_FAIL_RU = 'PRODUCTS_REQUEST_FAIL_RU';
export const PRODUCTS_REQUEST_FAIL_ARM = 'PRODUCTS_REQUEST_FAIL_ARM';
export const LOAD_MORE_PRODUCTS = 'LOAD_MORE_PRODUCTS';

export const PRODUCTS_TYPE_CAR_ALARM = 'car'
export const PRODUCTS_TYPE_MOTO_ALARM = 'motorcycle'
export const PRODUCTS_TYPE_IMMOBILIZERS = 'immobilizers'
export const PRODUCTS_TYPE_ACCESSORIES = 'accessories'


export const PRODUCTS_SERIES_ALL = 'ALL'
export const PRODUCTS_SERIES_DXL = 'DXL'
export const PRODUCTS_SERIES_DX = 'DX'
export const PRODUCTS_SERIES_X = 'X'
export const CHANGE_PRODUCT_SERIES = 'CHANGE_PRODUCT_SERIES'

// todo-----------------------------------------------
// export  const IGNITION_TYPE_START_STOP = 'IGNITION_TYPE_START_STOP';
export const IGNITION_TYPE_START_STOP = 'start/stop';
export const IGNITION_TYPE_KEY = 'IGNITION_TYPE_KEY';
export const IGNITION_TYPE_HYBRID = 'IGNITION_TYPE_HYBRID';


